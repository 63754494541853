import React, { useEffect, useRef } from "react"
import Hls from "hls.js"
import { isFunction } from "lodash"

export const StreamingVideo = React.forwardRef(
  ({ src, className, onCanPlay }, ref) => {
    let siblingRef = useRef(null)

    useEffect(() => {
      console.log("am init")
      let video = document.createElement("video")
      if (className) video.classList.add(className)
      video.controls = false
      video.muted = false
      video.loop = false
      video.playsInline = true
      siblingRef.current.parentElement.insertBefore(video, siblingRef.current)

      video.addEventListener("canplaythrough", () => onCanPlay?.(), {
        once: true,
      })

      if (isFunction(ref)) {
        ref(video)
      } else if (ref) {
        ref.current = video
      }

      if (Hls.isSupported()) {
        let hls = new Hls({
          capLevelOnFPSDrop: true,
          // debug: true,
          liveSyncDurationCount: 2,
          initialLiveManifestSize: 2,
          liveMaxLatencyDurationCount: 3,
        })

        hls.loadSource(src)
        hls.attachMedia(video)

        return () => {
          video.pause()
          hls.destroy()
          video.remove()
          if (className) video.classList.add(className)
        }
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src
        video.load()

        return () => {
          video.pause()
          video.src = ""
          video.remove()
          if (className) video.classList.add(className)
        }
      }
    }, [src, className, onCanPlay, ref])

    return <div style={{ display: "none" }} ref={siblingRef} />
  }
)
