import React, { useEffect, useRef, useState } from "react"

import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLink from "../components/pageLink"

import "./index.scss"
import {
  onBackToMain,
  onSetExitTo,
  BASE_TRANSITION_LENGTH,
  SHORT_TRANSITION_LENGTH,
} from "../transitions"

import BagpussVideo from "../videos/bagpuss.mp4"
import TwitterVideo from "../videos/twitter.mp4"
import { StreamingVideo } from "../components/streamingvideo"

const IndexPage = ({ transitionStatus }) => {
  let bagpussRef = useRef()
  let twitterRef = useRef()
  let [bagpussPlaying, setBagpussPlaying] = useState(false)
  let [twitterPlaying, setTwitterPlaying] = useState(false)

  let showreelPlayerRef = useRef()
  let [showreelPlaying, setShowreelPlaying] = useState(false)
  useEffect(() => {
    showreelPlayerRef.current.addEventListener("ended", () => {
      setShowreelPlaying(false)
    })
  }, [])

  let playShowreel = evt => {
    evt.preventDefault()
    if (showreelPlaying) {
      showreelPlayerRef.current.pause()
      setShowreelPlaying(false)
    } else {
      showreelPlayerRef.current.play()
      setShowreelPlaying(true)
    }
  }

  let playBagpuss = evt => {
    evt.preventDefault()
    setBagpussPlaying(true)
    let plays = 3
    let onEnded = () => {
      plays--
      if (plays === 0) {
        setBagpussPlaying(false)
        bagpussRef.current.removeEventListener("ended", onEnded)
      } else {
        bagpussRef.current.currentTime = 0
        bagpussRef.current.play()
      }
    }
    bagpussRef.current.addEventListener("ended", onEnded)
    bagpussRef.current.play()
  }

  let playTwitter = evt => {
    evt.preventDefault()
    setTwitterPlaying(true)
    let onEnded = () => {
      setTwitterPlaying(false)
      twitterRef.current.removeEventListener("ended", onEnded)
    }
    twitterRef.current.addEventListener("ended", onEnded)
    twitterRef.current.play()
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "page home",
        }}
        key="helmet"
      ></Helmet>
      <Layout transitionStatus={transitionStatus} page="home">
        <SEO title="" />
        <div className="playShowreelLink" onClick={playShowreel} />
        <PageLink
          to="/archives"
          className="transitionLink transitionLink--archives"
          exit={{
            length: SHORT_TRANSITION_LENGTH,
            zIndex: 0,
            trigger: args => onSetExitTo("main", args),
          }}
          entry={{
            length: SHORT_TRANSITION_LENGTH,
            zIndex: 10,
            trigger: args => onBackToMain("main", args),
          }}
        ></PageLink>
        <PageLink
          to="/awards"
          className="transitionLink transitionLink--awards"
          exit={{ length: SHORT_TRANSITION_LENGTH, zIndex: 0 }}
          entry={{ length: SHORT_TRANSITION_LENGTH, zIndex: 10 }}
        ></PageLink>
        <PageLink
          to="/contact"
          className="transitionLink transitionLink--contact"
          exit={{ length: SHORT_TRANSITION_LENGTH, zIndex: 0 }}
          entry={{ length: SHORT_TRANSITION_LENGTH, zIndex: 10 }}
        ></PageLink>
        <PageLink
          to="/directors"
          className="transitionLink transitionLink--directors"
          exit={{
            length: BASE_TRANSITION_LENGTH,
            zIndex: 0,
            trigger: args => onSetExitTo("main", args),
          }}
          entry={{
            length: BASE_TRANSITION_LENGTH,
            zIndex: 10,
            trigger: args => onBackToMain("main", args),
          }}
        ></PageLink>

        <PageLink
          to="/archives"
          className="dialLink dialLink--archives"
          exit={{
            length: SHORT_TRANSITION_LENGTH,
            zIndex: 0,
            trigger: args => onSetExitTo("main", args),
          }}
          entry={{
            length: SHORT_TRANSITION_LENGTH,
            zIndex: 10,
            trigger: args => onBackToMain("main", args),
          }}
        ></PageLink>
        <PageLink
          to="/awards"
          className="dialLink dialLink--awards"
          exit={{ length: SHORT_TRANSITION_LENGTH, zIndex: 0 }}
          entry={{ length: SHORT_TRANSITION_LENGTH, zIndex: 10 }}
        ></PageLink>
        <PageLink
          to="/contact"
          className="dialLink dialLink--contact"
          exit={{ length: SHORT_TRANSITION_LENGTH, zIndex: 0 }}
          entry={{ length: SHORT_TRANSITION_LENGTH, zIndex: 10 }}
        ></PageLink>
        <PageLink
          to="/directors"
          className="dialLink dialLink--directors"
          exit={{
            length: BASE_TRANSITION_LENGTH,
            zIndex: 0,
            trigger: args => onSetExitTo("main", args),
          }}
          entry={{
            length: BASE_TRANSITION_LENGTH,
            zIndex: 10,
            trigger: args => onBackToMain("main", args),
          }}
        ></PageLink>

        <a
          className="socialLink socialLink--twitter"
          href=""
          target="_blank"
          rel="noopener noreferrer"
          onClick={playTwitter}
        >
          Twitter
        </a>
        <a
          className="socialLink socialLink--instagram"
          href="//instagram.com/gorgeous.tv"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <button
          className="socialLink socialLink--gorgeous"
          href=""
          target="_blank"
          rel="noopener noreferrer"
          onClick={playBagpuss}
        >
          Gorgeous
        </button>
        <div className="background"></div>
        <div
          className={`inlineVideo bagpuss ${bagpussPlaying ? "isVisible" : ""}`}
        >
          <video className="bagpussVideo" ref={bagpussRef}>
            <source src={BagpussVideo} type="video/mp4" />
          </video>
        </div>
        <div
          className={`inlineVideo twitter ${twitterPlaying ? "isVisible" : ""}`}
        >
          <video className="twitterVideo" ref={twitterRef}>
            <source src={TwitterVideo} type="video/mp4" />
          </video>
        </div>
        <div className="showreelWrapper">
          <div className={`showreel ${showreelPlaying ? "playing" : ""}`}>
            <StreamingVideo
              ref={showreelPlayerRef}
              src="/showreel/showreel.m3u8"
            />
          </div>
        </div>
      </Layout>
      <script
        dangerouslySetInnerHTML={{
          __html: `
              if (window.netlifyIdentity) {
                window.netlifyIdentity.on("init", user => {
                  if (!user) {
                    window.netlifyIdentity.on("login", () => {
                      document.location.href = "/admin/";
                    });
                  }
                });
              }
          `,
        }}
      />
    </>
  )
}

export default IndexPage
